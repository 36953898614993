.full-width-img {
	height: auto;
	width: 100%;
}

.full-height-img {
	height: 100%;
}

.font-serif-1 {
	font-family: $font--primary-serif;
}

.bg-light-blue {
	background: $blue--lightpowdered;
}

.bg-light-gray {
	background: $gray--ash;
}

.bg-lilac {
	background: $purple--lilac;
}

.text-dark-blue {
	color: $blue--deep;
}

.text-dark-gray {
	color: $black--slate
}

.list-no-style {
	list-style: none;
	margin: 0;
	padding: 0;
}

.serif-headline-1 {
	font-size: 24px;
	font-weight: 400;
}

.align-center {
	text-align: center;
}

