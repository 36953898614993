// Custom colors
$black--slate: #4A4A4A;
$blue--deep: #0055A6; 
$blue--lightpowdered: #CCEDFF;
$blue--ucla: #1D4481;//00A5FF;
$blue80--ucla: #4A689A;
$blue60--ucla: #778FB3;
$blue40--ucla: #A4B4CD;
$gray--ash: #F7F7F7;
$gray--steel: #D8D8D8;
$purple--flourescent: #811D76;// UCLA Arts color#8237FF
$purple--lilac: #E7D8FF;
$yellow--ucla: #F2D43A; //#FFE800;
$yellow80--ucla: #F5DD61;
$yellow60--ucla: #F6E589;
$yellow40--ucla: #FAEEB1;
$orange--ucla: #F2D43A; //UCLA Arts color



// Font stacks
$font--primary-sans: 'PT Sans Narrow', sans-serif;
$font--secondary-sans: 'PT Sans', sans-serif;
$font--primary-serif: 'Crete Round', serif;

// Bootstrap overrides
$body-color: $black--slate;
$font-family-sans-serif: $font--primary-sans;

// Bootstrap theme overrides
$theme-colors: (
	"blue": $blue--ucla,
	"primary": $blue--ucla, //$blue--deep,
  "secondary": $blue--lightpowdered,
	"info": $yellow--ucla,
	"deep": $blue--ucla,
	"warning": $yellow--ucla,
	"alert": $orange--ucla
);



//primary was ucla now deep cb march 8 2020 (for wcag contrast on links)

// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";

// Other imports
@import "buttons";
@import "dropdowns";
@import "navbar";
@import "utilities";
@import "cards";
@import "tables";
@import "home";

// UCLA modules
@import "./modules/50-50";
@import "./modules/faculty";
@import "./modules/misc-fixes";

// Global overrides
body {
	-webkit-font-smoothing: antialiased;
	-webkit-text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}
