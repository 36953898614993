.navbar {
	
	.nav-link {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 2px;
		text-transform: uppercase;

		@include media-breakpoint-down(md) {
			font-size: 12px;
			letter-spacing: 2px;
		}
	}

	.dropdown-toggle::after {
		display: none;
	}

	.dropdown-item:hover, .dropdown-item:focus {
		/*background-color: initial;*/
		background-color: $blue--deep;
		color:white;
	}

	.navbar-brand img {
		width: 150px/*118px*/;
		@include media-breakpoint-down(md) {
			width: 150px/*88px*/;
		}
	}
	
}

