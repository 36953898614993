/*.event-img-small {
	max-width: 250px;
	max-height: 200px;
	overflow: hidden;
}*/

.twoline  {
	/*line-height: 200%;
	margin-top: -7px;*/
}

.featured-home {
	height: 300px; 
	/*background: $gray--steel;*/
	overflow: hidden;
	text-align:center;
	/* possible centering? */
}

.event-flyers {
	min-height: 250px;
	/*overflow: hidden;*/
}


.featured-home-col {
	min-height:550px;
	}
	
.featured-home-img{
	max-height:100%;
	max-width:100%;
	}
	
/* alert bar */
.alertbar {
	font-size: 1.25rem;
	line-height: 1.2;
	margin-bottom: 0px;
	margin-top: -12px;
}

/* skip link on all nav */
a.skip-main {
    left:-999px;
    position:absolute;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    z-index:-999;
}
a.skip-main:focus, a.skip-main:active {
    color: #fff;
    background-color:#000;
    left: auto;
    top: auto;
    width: auto;
    height: auto;
    overflow:auto;
    /* margin: 10px 35%; */
    padding:5px;
    /*border-radius: 15px;
    border:4px solid yellow;*/
    text-align:center;
    font-size:1.2em;
    z-index:999;
}

/* header redrawing*/
h1, h2, h3 {
	font-size: 1.25rem;
}	

.current_students {
	font-size: 1rem;
	font-family: "PT Sans Narrow", sans-serif;
	line-height: 1.2;
	
}

.event_head {
	line-height: 1.2;
	font-weight: 500;
	font-size: 1rem;
}

.calltoaction {
	font-size: 1.5rem;
	}
	
.bigtext, .area_faculty_name{
	font-family: 'Crete Round', serif;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.2;
	margin-bottom: 8px;
}

.area_faculty_name{
	margin-bottom: 40px;
}

.dept_spaces {
	font-size: 1rem;
	font-family: "PT Sans Narrow", sans-serif;
	font-weight: 700;
	line-height: 1.5;
	margin-bottom: 16px;
	
}

.header15rem {
	font-size: 1.5rem;
}

.area_sans_big {
	font-family: "PT Sans Narrow", sans-serif;
	font-size: 18px;
	line-height: 1.5;
}

.insta-outline {
	//border: 1px #e6e6e6 solid;
	}
	
#feedback-button{
	/*margin-top: 20px;
	margin-bottom: 50px;*/
	}