/* General  */
body {
    padding: 0;
    font-family: 'PT Sans Narrow', sans-serif;
}

p {
    font-size: 18px;
}

hr {
    width: 80%;
    background-color: gray;
    height: .5px;
}

a {
    font-family: 'PT Sans Narrow', sans-serif;
    color: rgb(58, 99, 175);
}

h1, h2, h3, h4, h5, th{ /*lowest h needs to stay sans serif*/
    font-family: 'Crete Round', serif;
}

.btn {
    border-radius: 0px;
}

.btn-light {
    border-style: solid;
    border-color: black;
    margin: 20px 0px;
    padding: 10px 30px;
    font-size: 20px;
    width: 250px !important;
}

/* Home Page */

.homePage {
    min-height: 3000px;
}

.homeHero {
    background-image: url("/assets/uploads/home_main_1140x500.jpg");
    /*background-image: url("http://placehold.it/1140x500");*/
    background-size:cover;
    width: 100%;
    height: 500px;
    float: left;
    position: relative;
    padding: 10px 0px;
    margin: 20px 10px;
    /*transition: background 1s linear;*/
}

.homeHero h5, .homeHero h1{
    color: white;
    height: 120px;
    width: 40%;
    padding: 15px 10px;
    margin-top: 150px;
}

.homeHero button {
    font-family: 'Crete Round', serif;
    padding: 10px 10px;
}

.homeStudies {
	font-size: 1.5rem;
}

.homeThree {
	font-size: 18px;
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	margin-bottom: 16px;
}

.homeBanner {
    min-height: 675px;
}

.homeBanner p{
    text-align: center;

}

.cardHomeBanner,
.collapseHomeBanner {
    position: absolute;
    background-color: $gray--ash;
    width: 70%;
    right: 55px;
    top: 300px;
    min-height: 235px;
}

.cardHomeBanner p {
    font-size: 13px;
    text-align: left;
    color: $black--slate;
}

.cardHomeBanner h4 {
    color: $black--slate;
}

.cardHomeBanner #faculty_name_card, .cardHomeBanner #event_title_card,  .cardHomeBanner #insta_title_card_left, .cardHomeBanner #insta_title_card_center, .cardHomeBanner #insta_title_card_right {
	font-size: 1.5rem;
	font-family: 'Crete Round', serif;
	font-weight: 500;
	line-height: 1.2;
}

.gridLeft,
.gridLeft a {
    background-color: $blue--lightpowdered;
    color: $black--slate;
}

.gridRight,
.gridRight a {
    background-color: $blue--ucla; //#0055A6;
    color: white;
}

.footerHome {
    /* cb edit position: absolute;
    top: 3000px;*/
    width: 100%;
    background-color: $blue--ucla;//#0055A6;
    color: #FFFFFF;
}

.footerHome a {
    color: $blue--lightpowdered;
}

.footerHome ul {
    list-style: none;
}

.footerHome button {
    /*color: #0055A6;*/
    /*background-color: #CCEDFF;*/
    max-height: 70px;
    font-weight: bold;
}

.collapse {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $black--slate;
}

.address {
    margin-top: -20px;
}

.statement-list{
	list-style-type:none;
	padding: 0;
}

.statement-list li {
	margin-bottom: 1.1rem;
}

.featured-home-text {
	background-color: $gray--ash;
	padding: 10px;
}


mark {
    background-color: transparent;//$purple--flourescent; //rgb(117, 62, 206);
    color: $black--slate;
    //padding: 8px;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.8rem;
}

/* Undergrad Studies Page */
.undergrad {
    min-height:400px;
}
.card-text p{
    font-size: 14px !important;
}

.footer {
    text-align: center;
    width: 100%;
    margin-top: 50px;
    padding: 60px 0px;
    left: 0;
    bottom: 0;
    color: white;
}

#event-loc {
    margin-top: -15px;
}

#spaces p{
    font-size: 16px;
}
#spaces li {
    font-size: 16px;
}



/* Interdisciplinary Studio */
.interHero {
    background-color: $purple--lilac;
}

.studio {
    border-style: solid;
    border-color: #ff0000;//#8237FF; ? not sure where used
    border-width: 1px;
}

.card p{
    font-size: 15px; /* cb increased from 11px */
}

#studio {
    background-color: #00ff00;//#8237FF; ? not sure where used
    color: white;
}

/* cb test removal .grad {
    border-color: $blue--ucla; //#0055A6;
    border-style: solid;
    border-width: 1px;
} */

.grad {
    min-height:350px;
}

.grad-studios {
	min-height:275px;
}

.program-overview {
	min-height:250px;
	}

.aedi-box {
	min-height:330px;
	margin-bottom:30px;
}

#grad {
    background-color: $blue--ucla; //#0055A6;
    color: white;
}

.light-blue {
    background-color: $purple--lilac;
}



/* New Genres */
/* First Carousel */
#bigCarousel {
    text-align: center;
    width: 100%;
    margin: 0 auto;
}

.prev {
    left: -20px;
    bottom: 0px;
}

.next {
    right: -20px;
    bottom: 0px;
}

.controls {
	height:450px;
	position: sticky;
}

.controls-sm {
	height:250px;
	position: sticky;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  background-size: 100%, 100%;
 background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: gray;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: gray;
}

.flex-column {
    color: $black--slate;
    text-align: left;
}

.carousel-fixer {
	margin-left: 10%;
	width: 80%;
	//border: 1px solid orange;
}

/* Second Carousel */

#newWorkPrev {
    left: 0px;
}

#newWorkNext {
    right: 0px;
}

.newWork {
    text-align: center;
    font-size: 20px;
}

#genres {
    background-color: $blue--ucla;
    color: white;
}

.genres {
    border-style: solid;
    border-color:  $blue--ucla;
    border-width: 1px;
}

.genres_faculty {
	margin-bottom: 0px;
}

.mfa p{
    font-size: 18px;
}

.threePic {
    height: 100%;
    width: 100%;
    padding: 10px 0px;
    float: left;
    position: relative;
    background-image: url("/assets/uploads/resources-default.jpg");
    background-size: cover;
    margin: 20px 10px;
    }

.threePic p {
    background-color: $blue--ucla; //#0055A6;
    color: white;
    height: 40px;
    padding: 5px 5px;
    margin-top: 125px;
    width: 80%;
    margin-left: 10px;
}

.threePic a {
    color: white;
}

/* Alumni Highlights */
#highlights-header {
    background-color: $blue--lightpowdered;
}

.heroBG {
    background-color: $blue--lightpowdered;
    color: #4A4A4A;
}

#highlights-body{
    text-align: center;
}

tr:nth-child(odd) {
    background-color: $blue--lightpowdered;
  }

tr:nth-child(even) {
    background-color: $gray--ash;
  }

  th, td {
    padding: 10px;
    /* cb remove line-height: 10px;*/
    text-align: left;
  }

  th {
      background-color: $blue--ucla; //#0055A6;
      color: white;
      min-width: 150px;
  }

  .table {
      margin-right: 50px;
  }

  /* Event Details Page */
  .exhibition {
      font-size:15px;
      line-height: 1.2;
  }

  /* AEDI page */
  .aedi_action {}

  .aedi_action_hide {
  	visibility: hidden;
  	overflow: hidden;
  	height: 0px;
  	}

  .aedi_action_show {
  	  visibility: visible;

  }


@media screen and (min-width: 768px) {
    #current_students {
        text-align: center;
    }
    .saiVid {
    	width:640px;
    	height:360px;
    }
}

@media screen and (max-width: 1199px) {
    td {
        line-height: 1.2;
    }
}

@media screen and (max-width: 767px) {
    .carousel-control-prev {
        left: 20px;
    }

    .carousel-control-next {
        right: 20px;
    }

    .controls {
    	height:200px;
    }

    .threePic {
        height: 200px;
        width: 300px;
    }

    .homeHero h5, .homeHero h1 {
        width: 90%;
        height: 200px;
    }

    .saiVid {
    	width:320px;
    	height:180px;
    }
}

@media screen and (min-width: 768px) {
    .homeHero h5, .homeHero h1 {
        width: 55%;
    }
}
