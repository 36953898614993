.btn--nav {
	border-radius: 0px;
	font-size: 12px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.btn-block {
	background-color: $gray--steel;
	border-color: black;
	border-style: solid;
	border-radius: 0;
	margin: 20px 0px;
	padding: 10px 20px;
	width: 100% !important;

	&:hover {
		color: white;
		background-color: $blue--ucla;
	}
}
