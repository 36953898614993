.purple-box {
    border-style: solid;
    border-color: $purple--flourescent;
    border-width: 1px;
}

.purple-box-header {
	background: $purple--flourescent;
	color: $gray--ash;
}

.blue-box {
	border-style: solid;
    border-color: $blue--ucla; /*$blue--deep;*/
    border-width: 1px;
}

.blue-box-header {
	color: $gray--ash;
	background: $blue--ucla; /*$blue--deep;*/
}

.lt-blue-box {
	border-style: solid;
    border-color: $blue--ucla; /*$blue--deep;*/
    border-width: 1px;
}

.lt-blue-box-header {
	color: $gray--ash;
	background: $blue--ucla; /*$blue--deep;*/
}